import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  InputAdornment,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Spinner } from '@common/Spinner';
import { menuApi } from '@services/api';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CommonActionMenu } from 'components/menu/common-actions-menu';
import { EmptyContent } from 'components/menu/empty-content';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fillRoute, routes } from '@services/routes';
import ConfirmationModal from '@common/modals/ConfirmationModal';

export default function ItemsPage(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const { clubId, menuId, sectionId } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { i18n } = useLingui();
  const { isLoading, data } = useQuery(['getMenuSection', clubId, menuId, sectionId], () =>
    menuApi.getMenuSection(clubId, menuId, sectionId),
  );

  const deleteMutation = useMutation(
    itemId => menuApi.deleteMenuItem(clubId, itemId, menuId, sectionId),
    {
      onSuccess: () => queryClient.invalidateQueries(['getMenuSection', clubId, menuId, sectionId]),
    },
  );

  const updateItemsMutation = useMutation(
    items => menuApi.batchCreateOrUpdateMenuItem(clubId, menuId, sectionId, { items }),
    {
      onSuccess: () => queryClient.invalidateQueries(['getMenuSection', clubId, menuId, sectionId]),
    },
  );

  const onDeleteConfirm = () => {
    deleteMutation.mutate(selectedItem.id);
    setShowDeleteModal(false);
  };

  const onClickDelete = item => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  if (isLoading || deleteMutation.isLoading || updateItemsMutation.isLoading) {
    return <Spinner />;
  }

  // Sorts item by disabled and position
  const sortedItems = data.items.sort((a, b) =>
    a.disabled - b.disabled === 0 ? a.position - b.position : a.disabled - b.disabled,
  );
  const filteredItems = sortedItems.filter(
    item =>
      item.name?.toUpperCase().includes(searchQuery) ||
      item.description?.toUpperCase().includes(searchQuery) ||
      item.ingredients?.toUpperCase().includes(searchQuery),
  );

  const onDragAndDrop = droppedItem => {
    if (!droppedItem.destination || droppedItem.source.index === droppedItem.destination.index)
      return;
    const [reorderedItem] = sortedItems.splice(droppedItem.source.index, 1);
    sortedItems.splice(droppedItem.destination.index, 0, reorderedItem);
    sortedItems.forEach((item, index) => (item.position = index));
    updateItemsMutation.mutate(sortedItems);
  };

  const searchItems = ({ target }) => {
    setSearchQuery(target.value?.toUpperCase());
  };

  return (
    <TopbarPageLayout
      title={data.name}
      onBack={fillRoute(routes.menuSectionList, { clubId, menuId })}
    >
      <Container
        sx={{
          py: { xs: 4, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
          mb: 20,
        }}
      >
        {sortedItems?.length > 0 && (
          <Stack sx={{ mb: 2.5 }}>
            <TextField
              placeholder={i18n._(`Search dishes, wines...`)}
              value={searchQuery}
              onChange={searchItems}
              hiddenLabel
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'text.disabled', mr: 1 }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
        {filteredItems?.length > 0 ? (
          <DragDropContext onDragEnd={onDragAndDrop}>
            <Droppable droppableId="list-container">
              {provided => (
                <Grid
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  container
                  spacing={1.5}
                  justifyContent="center"
                >
                  {filteredItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {provided => (
                        <Grid
                          item
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          xs={12}
                          md={8}
                        >
                          <Card
                            onClick={() =>
                              history.push(
                                fillRoute(routes.menuItemEdit, {
                                  clubId,
                                  menuId,
                                  sectionId,
                                  itemId: item.id,
                                }),
                              )
                            }
                            spacing={2}
                            sx={{
                              px: 3,
                              py: 2,
                              pr: 1,
                              borderRadius: 2,
                              backgroundColor:
                                item.disabled || item.outOfStock
                                  ? 'rgba(230, 230, 230, 0.7)'
                                  : null,
                              boxShadow:
                                item.disabled || item.outOfStock
                                  ? '0px 0px 0px rgba(0,0,0,0.02)'
                                  : null,
                              opacity: item.disabled || item.outOfStock ? 0.5 : 1,
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{ typography: 'subtitle1' }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                flexGrow={1}
                                sx={{ minWidth: 0 }}
                              >
                                <Typography
                                  sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    flexGrow: 1,
                                    marginRight: 1,
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </Stack>

                              <Stack direction="row" alignItems="center">
                                {item.disabled && <VisibilityOffIcon color="action" />}
                                <CommonActionMenu onClickDelete={() => onClickDelete(item)} />
                              </Stack>
                            </Stack>

                            <Stack spacing={1} sx={{ pr: 1 }}>
                              {(item.bestSeller || item.suggested) && (
                                <Box>
                                  <Chip
                                    label={
                                      item.bestSeller ? i18n._('Bestseller') : i18n._('Recommended')
                                    }
                                    sx={{
                                      ml: 1,
                                      bgcolor: item.bestSeller
                                        ? (props.colorTheme &&
                                            props.colorTheme.bestSellerLabelColor) ||
                                          '#E67A00'
                                        : (props.colorTheme &&
                                            props.colorTheme.suggestedLabelColor) ||
                                          '#007AE6',
                                      color: '#FFFFFF',
                                    }}
                                  />
                                </Box>
                              )}

                              {item.description && (
                                <Stack spacing={0.5}>
                                  <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                                    {i18n._('Description')}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {item.description}
                                  </Typography>
                                </Stack>
                              )}
                              {item.ingredients && (
                                <Stack spacing={0.5}>
                                  <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                                    {i18n._('Ingredients')}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      maxWidth: '100%',
                                    }}
                                  >
                                    {item.ingredients}
                                  </Typography>
                                </Stack>
                              )}
                              {item.allergens && (
                                <Stack spacing={0.5}>
                                  <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                                    {i18n._('Allergens')}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {item.allergens}
                                  </Typography>
                                </Stack>
                              )}

                              {(item.priceText || item.price) && (
                                <Typography
                                  sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {item.priceText ? item.priceText : `${item.price} €`}{' '}
                                </Typography>
                              )}
                              {item.outOfStock && (
                                <Typography
                                  sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    color: '#fb535b',
                                  }}
                                >
                                  {i18n._('Out of stock')}
                                </Typography>
                              )}
                            </Stack>
                          </Card>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <EmptyContent text={i18n._('There are no items in this category.')} />
        )}
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            p: 3,
            pt: 2,
            display: 'flex',
            justifyContent: 'space-around',
            bgcolor: '#f5f5f5',
          }}
        >
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() =>
              history.push(fillRoute(routes.menuItemNew, { clubId, menuId, sectionId }))
            }
            sx={{
              borderRadius: '25px',
              px: 8,
              py: 1.5,
              bgcolor: '#397FF7',
            }}
          >
            {i18n._('Add Item')}
          </Button>
        </Box>
        <ConfirmationModal
          show={showDeleteModal}
          title={`${i18n._('Delete item')} : ${selectedItem?.name}`}
          onConfirm={onDeleteConfirm}
          onClose={() => setShowDeleteModal(false)}
        />
      </Container>
    </TopbarPageLayout>
  );
}
