import React from 'react';
import { Spinner } from '@common/Spinner';
import { Box, Button, Container, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLingui } from '@lingui/react';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { menuApi, bannerApi } from '@services/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MultiselectionModal } from 'components/modals/multiselection-modal';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';
import { useSnackbar } from 'notistack';

export default function BannerChangePage(props) {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { clubId, menuId } = useParams();
  const queryClient = useQueryClient();
  const [prefetchingBanner, setPrefetchingBanner] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLingui();
  const onChangePromotionClick = () =>
    history.push(fillRoute(routes.bannerNew, { clubId, menuId }));
  const onChangeDetailsClick = () =>
    history.push(fillRoute(routes.bannerDetails, { clubId, menuId }));
  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    mutation.mutate();
  };

  const { isLoading, data } = useQuery(['getMenu', clubId, menuId], async () => {
    const response = await menuApi.getMenu(clubId, menuId);

    // Prefetch banner
    const Banner = new Image();
    Banner.src = response.bannerUrl;
    Banner.onload = () => setPrefetchingBanner(false);
    Banner.onerror = () => {
      setPrefetchingBanner(false);
      enqueueSnackbar(
        i18n._('There was a network error when uploading the last banner. Please upload it again'),
      );
      history.replace(fillRoute(routes.bannerNew, { clubId, menuId }));
    };

    return response;
  });

  const mutation = useMutation(() => bannerApi.deleteBanner(clubId, menuId), {
    onSuccess: async () => {
      // Invalidate and refetch
      await queryClient.invalidateQueries(['getMenu', ['getClubMenuList', clubId]]);
      history.replace(fillRoute(routes.menuList, { clubId }));
    },
  });

  if (isLoading || mutation.isLoading || prefetchingBanner) {
    return <Spinner />;
  }

  return (
    <TopbarPageLayout
      title={i18n._('Promotion/Event')}
      onBack={fillRoute(routes.menuList, { clubId })}
    >
      <Container
        maxWidth="lg"
        sx={{
          py: { xs: 6, md: 8 },
          px: { xs: 4, md: 16 },
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={data.bannerUrl}
            onLoad={() => setPrefetchingBanner(false)}
            alt="banner"
            style={{
              maxWidth: '100%',
              maxHeight: '70vh',
              objectFit: 'contain',
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            p: 3,
            pt: 2,
            display: 'flex',
            justifyContent: 'space-around',
            bgcolor: '#f5f5f5',
          }}
        >
          <IconButton
            aria-label="delete"
            aria-controls="long-menu"
            aria-haspopup="false"
            onClick={() => setShowDeleteModal(true)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          <Stack spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onChangePromotionClick()}
              sx={{
                borderRadius: '25px',
                px: 8,
                py: 1.5,
                bgcolor: '#397FF7',
              }}
            >
              {i18n._('Change promotion')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onChangeDetailsClick()}
              sx={{
                borderRadius: '25px',
                px: 8,
                py: 1.5,
              }}
            >
              {i18n._('Change details')}
            </Button>
          </Stack>
        </Box>
        <MultiselectionModal
          show={showDeleteModal}
          title={`${i18n._('Delete promotion on menu')} - ${data.adminDisplayName}`}
          onConfirm={onConfirmDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Container>
    </TopbarPageLayout>
  );
}
