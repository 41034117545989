import React from 'react';
import ErrorBoundary from '@common/ErrorBoundary';
import AuthenticatedRoute from '@common/authenticated-route';
import { light } from '@common/theme/light';
import { QrCodeMenuDetails } from '@features/qrCodeMenu/QrCodeMenuDetails';
import { QrCodeMenus } from '@features/qrCodeMenu/QrCodeMenus';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages as englishMessages } from '@locales/en/messages';
import { messages as italianMessages } from '@locales/it/messages';
import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import CloseIcon from '@mui/icons-material/Close';
import AccountDeletionRequestPage from '@pages/account-deletion-request-page';
import AlertPage from '@pages/alert-page';
import LoginPage from '@pages/auth/login-page';
import PasswordRecoveryPage from '@pages/auth/password-recovery-page';
import PasswordResetPage from '@pages/auth/password-reset-page';
import SignupPage from '@pages/auth/signup-page';
import ClubPage from '@pages/club-page';
import { AdminClubSettings } from '@pages/club-settings/admin-club-settings';
import { ClubSettingsBoardPage } from '@pages/club-settings/board-page';
import { ClubQrCode } from '@pages/club-settings/club-qr-code';
import { ClubContactsPage } from '@pages/club-settings/contacts-page';
import ClubPaymentPlan from '@pages/club-settings/payment-plan';
import BannerChangePage from '@pages/menu/banner-change-page';
import BannerCreationPage from '@pages/menu/banner-creation-page';
import BannerDetailsPage from '@pages/menu/banner-details-page';
import CategoriesPage from '@pages/menu/categories-page';
import { EditItemPage } from '@pages/menu/edit-item-page';
import { EditMenuPage } from '@pages/menu/edit-menu-page';
import ItemsPage from '@pages/menu/items-page';
import MenuSchedulingPage from '@pages/menu/menu-scheduling-page';
import MenusPage from '@pages/menu/menus-page';
import PaymentPage from '@pages/payment-page';
import SplashPage from '@pages/splash-page';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { logout } from '@services/api';
import { languages, routes } from '@services/routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserHistory } from 'history';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';

i18n.load({
  en: englishMessages,
  it: italianMessages,
});
i18n.activate(languages[0]);

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);
const SentryAuthenticatedRoute = Sentry.withSentryRouting(AuthenticatedRoute);
if (['production', 'development'].includes(import.meta.env.MODE)) {
  Sentry.init({
    dsn:
      'https://8b1e80df77654b9f8020db2e5504581c@o4504532881113088.ingest.sentry.io/4504532885110784',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory()),
      }),
    ],
    environment: import.meta.env.MODE,
    tracesSampleRate: 0.1,
  });
}
console.log(import.meta.env.MODE);
export default function App() {
  const SnackbarDismissButton = ({ id }) => {
    const { closeSnackbar } = useSnackbar();
    return (
      <CloseIcon
        onClick={() => {
          closeSnackbar(id);
        }}
      />
    );
  };

  const MainContent = () => {
    const { enqueueSnackbar } = useSnackbar();

    const updateSW = registerSW({
      onNeedRefresh() {
        enqueueSnackbar(i18n._('Update found, click here to install it'), {
          variant: 'info',
          onClick: updateSW,
          persist: true,
          action: () => {},
        });
      },
    });

    const clientSharedOptions = {
      retry: (failureCount, error) => {
        return failureCount <= 3 && '4' !== error?.status?.toString().charAt(0);
      },
      onError: error => {
        if (error.status === 401) {
          logout();
          enqueueSnackbar(i18n._('Your session has expired, please log in again'), {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(i18n._('An error occurred'));
          Sentry.captureException(error);
        }
      },
    };
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: { ...clientSharedOptions, refetchOnWindowFocus: false },
        mutations: clientSharedOptions,
      },
    });

    return (
      <QueryClientProvider client={queryClient}>
        <I18nProvider i18n={i18n}>
          <MuiThemeProvider theme={light}>
            <CssBaseline />
            <Switch>
              <SentryAuthenticatedRoute
                path={[routes.menuItemNew, routes.menuItemEdit]}
                component={EditItemPage}
              />
              <SentryAuthenticatedRoute path={routes.clubNew} component={ClubPage} />
              <SentryAuthenticatedRoute path={routes.clubPayment} component={PaymentPage} />
              <SentryAuthenticatedRoute path={routes.menuItemList} component={ItemsPage} />
              <SentryAuthenticatedRoute
                path={routes.menuSchedulingList}
                component={MenuSchedulingPage}
              />
              <SentryAuthenticatedRoute path={routes.menuEdit} component={EditMenuPage} />
              <SentryAuthenticatedRoute path={routes.menuNew} component={EditMenuPage} />
              <SentryAuthenticatedRoute
                path={[routes.bannerDetails, routes.bannerDetailsNew]}
                component={BannerDetailsPage}
              />
              <SentryAuthenticatedRoute path={routes.bannerNew} component={BannerCreationPage} />
              <SentryAuthenticatedRoute path={routes.menuBanner} component={BannerChangePage} />
              <SentryAuthenticatedRoute path={routes.menuSectionList} component={CategoriesPage} />
              <SentryAuthenticatedRoute path={routes.menuList} component={MenusPage} />
              <SentryAuthenticatedRoute
                path={routes.clubProfileContacts}
                component={ClubContactsPage}
              />
              <SentryAuthenticatedRoute
                path={routes.clubProfileSettings}
                component={AdminClubSettings}
              />
              <SentryAuthenticatedRoute
                path={routes.clubProfileManagePlan}
                component={ClubPaymentPlan}
              />
              <SentryAuthenticatedRoute path={routes.clubQrCode} component={ClubQrCode} />
              <SentryAuthenticatedRoute
                path={routes.clubProfileList}
                component={ClubSettingsBoardPage}
              />
              <SentryRoute path={routes.alert} component={AlertPage} />
              <SentryRoute path={routes.qrMenuView} component={QrCodeMenuDetails} />
              <SentryRoute path={routes.qrMenuList} component={QrCodeMenus} />
              <SentryRoute path={routes.signup} component={SignupPage} />
              {/* TODO: questo mapping che è?*/}
              <SentryRoute path={routes.subscriptionPayment} component={PaymentPage} />
              <SentryRoute path={routes.login} component={LoginPage} />
              <SentryRoute path={routes.passwordReset} component={PasswordResetPage} />
              <SentryRoute path={routes.passwordRecovery} component={PasswordRecoveryPage} />
              <SentryRoute path={routes.accountDeletion} component={AccountDeletionRequestPage} />
              <SentryRoute path="*" component={SplashPage} />
            </Switch>
          </MuiThemeProvider>
        </I18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      action={key => <SnackbarDismissButton id={key} />}
      variant="error"
      preventDuplicate
    >
      <Router>
        <ErrorBoundary>
          <MainContent />
        </ErrorBoundary>
      </Router>
    </SnackbarProvider>
  );
}
