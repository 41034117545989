import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { Spinner } from '@common/Spinner';
import { loginApi, setIsAdmin } from '@services/api';
import { isLoggedIn, setAuthToken } from '@services/api';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLingui } from '@lingui/react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router-dom';
import { clubApi } from '@services/api';
import { fillRoute, routes } from '@services/routes';
import * as Sentry from '@sentry/react';

export default function LoginPage(props) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [totp, setTotp] = useState('');
  const [totpVisible, setTotpVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { i18n } = useLingui();
  // This is ok since the results are cached for the next page
  const { isInitialLoading } = useQuery([`getClubsOwned`], () => clubApi.getClubsOwned(), {
    enabled: isLoggedIn(),
    onSuccess: data => {
      if (data.length === 0) {
        history.replace(fillRoute(routes.clubNew));
      } else {
        history.replace(fillRoute(routes.menuList, { clubId: data[0].id }));
      }
    },
  });

  const mutation = useMutation(
    async () => {
      const {
        body: { token, totpEnabled, clubOwner },
      } = await loginApi(username, password, totp);
      if (totpEnabled) {
        setTotpVisible(true);
      }
      if (token) {
        // Invalidate and refetch
        await queryClient.invalidateQueries();
        await setAuthToken(token);
        setIsAdmin(!clubOwner);
        // User will be automatically redirected due to the getClubsOwned query triggering.
      }
    },
    {
      onError: error => {
        if (error.status === 401) {
          enqueueSnackbar(i18n._('Wrong username or password'), { variant: 'warning' });
        } else {
          enqueueSnackbar(i18n._('An error occurred'));
          Sentry.captureException(error);
        }
      },
    },
  );

  const renderHead = (
    <div>
      <Box
        sx={{
          width: '80%',
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          mb: 4,
        }}
      >
        <img
          src={`${import.meta.env.VITE_S3_URL}/assets/images/logo-horizontal-b.svg`}
          alt="logo"
          style={{ width: '100%' }}
        />
      </Box>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {i18n._('New to AirDrink?')}
        {` `}
        <Link
          component="button"
          onClick={() => history.push(routes.signup)}
          variant="subtitle2"
          color="primary"
        >
          {i18n._('Create an account')}
        </Link>
      </Typography>
    </div>
  );

  const renderForm = (
    <Stack spacing={2.5} alignItems="flex-end">
      <TextField
        label={i18n._('Phone number')}
        name="phone-number"
        value={username}
        onChange={({ target }) => setUsername(target.value)}
        required
        fullWidth
      />

      <TextField
        label={i18n._('Password')}
        name="password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        required
        fullWidth
      />

      <Collapse in={totpVisible} sx={{ width: '100%' }}>
        <TextField
          label={i18n._('TOTP')}
          name="totp"
          value={totp}
          onChange={({ target }) => setTotp(target.value)}
          required
          fullWidth
        />
      </Collapse>

      <Link
        component="button"
        variant="body2"
        underline="always"
        color="text.secondary"
        onClick={() => history.push(routes.passwordRecovery)}
      >
        {i18n._('Forgot password?')}
      </Link>

      <Button
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        disabled={!username || !password}
        onClick={mutation.mutate}
      >
        {i18n._('Login')}
      </Button>
    </Stack>
  );

  if (mutation.isLoading || isInitialLoading) {
    return <Spinner />;
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        background: `linear-gradient(to bottom, ${alpha('#FFFFFF', 0.9)}, ${alpha(
          '#FFFFFF',
          0.9,
        )}), url(/assets/background/overlay_1.jpg)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        px: 2,
        py: 12,
        minHeight: '100vh',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          p: 4,
          width: 1,
          mx: 'auto',
          flexShrink: 0,
          maxWidth: 400,
          borderRadius: 2,
          bgcolor: '#FFFFFF',
          boxShadow: `-24px 24px 72px -8px ${alpha('#919EAB', 0.24)}`,
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        {renderHead}

        {renderForm}
      </Stack>
    </Stack>
  );
}
