import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from '@common/Spinner';
import { GenericError } from '@common/GenericError';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { QrCodeMenuBody } from './QrCodeMenuBody';
import QrCodeBanner from './QrCodeBanner';
import { QrCodeRefreshPopup } from './QrCodeRefreshPopup';
import { QrCodeTutorial } from './QrCodeTutorial';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { menuApi } from '@services/api';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { createRef } from 'react';
import { QrCodeMenuInfoFooter } from './QrCodeMenuInfoFooter';
import { NotFoundError } from '../../common/NotFoundError';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { fillRoute, routes } from '@services/routes';
import QrMenuPageLayout from 'layout/qr-menu-page-layout';

const useStyles = makeStyles(theme => ({
  backgroundcontainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
  qrCodeMenucontainer: {
    display: 'grid',
    gridTemplateRows: 'min-content auto min-content',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
  headerContainer: {
    display: 'block',
    width: '100vw',
    height: theme.spacing(10),
    position: 'sticky',
    top: 0,
    zIndex: 11,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
  },
  topBarBack: {
    display: 'grid',
    position: 'absolute',
    zIndex: 2,
    top: theme.spacing(1),
    left: theme.spacing(3),
    height: theme.spacing(8),
    textAlign: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logoMenuContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(3),
    right: theme.spacing(3),
    height: theme.spacing(8),
    textAlign: 'center',
    alignItems: 'center',
  },
  logoImages: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    alignSelf: 'center',
  },
}));

export const QrCodeMenuDetails = props => {
  const classes = useStyles(props);
  const [bannerClosed, setBannerClosed] = useState(false);
  const [showRefreshPopup, setShowRefreshPopup] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [sortedMenuSections, setSortedMenuSections] = useState(undefined);
  const matches = useMediaQuery('(max-width:800px)');
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const showEmbedded = searchParams.get('embedded') == 'true';

  const queryClient = useQueryClient();
  const { clubId, tableId, menuId } = useParams();
  const history = useHistory();
  const menuContainerRef = createRef();

  const { isLoading, error, data } = useQuery(
    ['getMenu', clubId, menuId],
    async () => {
      const data = await menuApi.getMenu(clubId, menuId);

      const newSortedSections = data?.sections
        .filter(s => !s.disabled)
        .sort((a, b) => a.position - b.position);

      // TODO: remove lodash if it's used only for this and replace it with https://github.com/FormidableLabs/react-fast-compare
      // COMPARE WITHOUT ARRAY SORTING
      if (sortedMenuSections !== undefined && !_.isEqual(sortedMenuSections, newSortedSections)) {
        setShowRefreshPopup(true);
      }

      setSortedMenuSections(newSortedSections);

      return data;
    },
    {
      refetchInterval: data => data?.refreshTime * 1000 ?? false,
      refetchIntervalInBackground: true,
      onError: null,
    },
  );

  const goToMenuList = () =>
    history.push(fillRoute(routes.qrMenuList, { clubId, tableId }) + history.location.search);

  useEffect(() => {
    // The timer before showing the tutorial starts when the banner is closed if
    // one is present. Also, if the tutorial has been shown before, we don't show it again.
    const tutorialShownStorageKey = 'tutorial_shown_club_' + clubId;
    const tutorialShown = localStorage.getItem(tutorialShownStorageKey);
    if (
      !isLoading &&
      !tutorialShown &&
      matches &&
      (!data?.bannerUrl || (data?.bannerUrl && bannerClosed)) &&
      (!data?.banner || (data?.banner && bannerClosed))
    ) {
      setTimeout(() => {
        setShowTutorial(true);
        localStorage.setItem(tutorialShownStorageKey, new Date());
      }, 2500);
    }
  }, [data, clubId, isLoading, matches, queryClient, bannerClosed]);

  if (isLoading) {
    return <Spinner colorSpinner={'#fff'} />;
  }

  if (error) {
    if (error.status === 404) {
      return <NotFoundError />;
    }
    return <GenericError />;
  }

  const headerContainerStyle = {
    backgroundColor: data.theme.background,
    backgroundImage:
      data.theme && data.theme.backgroundImage ? `url(${data.theme.backgroundImage})` : 'inherit',
  };

  const topBarBackStyle = {
    color: data.theme && data.theme.text ? data.theme.text : 'inherit',
  };

  return (
    <QrMenuPageLayout theme={data.theme} translationsEnabled={data.translationsEnabled}>
      <QrCodeTutorial open={showTutorial} onClose={() => setShowTutorial(false)} />
      <QrCodeBanner
        theme={data.theme}
        url={data.bannerUrl}
        banner={data.banner}
        onClose={() => setBannerClosed(true)}
      />
      <QrCodeRefreshPopup theme={data.theme} open={showRefreshPopup} onClose={goToMenuList} />

      <div className={classes.qrCodeMenucontainer} ref={menuContainerRef}>
        <div className={classes.headerContainer} style={headerContainerStyle}>
          <div onClick={goToMenuList} className={classes.topBarBack} style={topBarBackStyle}>
            <ArrowBackIosNewIcon fontSize="small" />
          </div>
          {!showEmbedded && (
            <div className={classes.logoMenuContainer}>
              <img
                onClick={goToMenuList}
                className={classes.logoImages}
                src={data.logo}
                alt="logo"
                translate="no"
              />
            </div>
          )}
        </div>
        <QrCodeMenuBody
          menuSections={sortedMenuSections}
          colorTheme={data.theme}
          containerRef={menuContainerRef}
        />
        {!showEmbedded && (
          <QrCodeMenuInfoFooter
            footer={data.footer}
            colorTheme={data.theme}
            clubAddress={data.clubAddress}
            clubPhone={data.clubDisplayPhone}
            clubEmail={data.clubDisplayEmail}
            clubInstagram={data.clubInstagram}
            clubFacebook={data.clubFacebook}
            clubWebsite={data.clubWebsite}
            clubWhatsapp={data.clubWhatsapp}
          />
        )}
      </div>
    </QrMenuPageLayout>
  );
};
