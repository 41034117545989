import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Spinner } from '@common/Spinner';
import { useQuery } from '@tanstack/react-query';
import { clubApi } from '@services/api';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Divider from '@material-ui/core/Divider';
import { MultiselectionModal } from 'components/modals/multiselection-modal';
import { isAdmin, logout } from '@services/api';
import { useHistory, useParams } from 'react-router-dom';
import { FormControl, Select } from '@material-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { fillRoute, routes, languages } from '@services/routes';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { useLingui } from '@lingui/react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    display: 'grid',
    gridTemplateRows: 'min-content auto',
    gridTemplateColumns: 'auto',
    gridTemplateAreas: '"topbar" "content"',
    background: theme.palette.background,
    '& .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
  content: {
    gridArea: 'content',
    overflow: 'auto',
  },

  drawerContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(1),
    gridTemplateRows: 'auto 1fr',
    width: 300,
    maxWidth: '80vw',
    height: '100vh',
    background: theme.palette.background,
    overflow: 'auto',
  },
  drawerItemContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(2),
    gridAutoRows: 'min-content',
    padding: theme.spacing(2),
  },

  drawerContentTitle: {
    color: theme.palette.drawer.link.titleColor,
    fontWeight: '500',
    fontSize: '13px',
  },
  drawerItem: {
    cursor: 'pointer',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr min-content',
    minHeight: theme.spacing(4),
    alignItems: 'center',
  },
  drawerItemEnabled: {
    color: theme.palette.drawer.link.color,
    '&:hover': {
      color: theme.palette.drawer.link.hover.background,
    },
  },
  drawerItemDisabled: {
    color: theme.palette.drawer.link.disabled,
  },
  drawerLanguageContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(1),
    gridTemplateColumns: 'auto min-content min-content',
    background: theme.palette.background,
    padding: theme.spacing(1),
  },
  drawerLanguageItem: {
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
  },
  drawerLanguageItemSelected: {
    borderBottom: '1px solid ' + theme.palette.input.error,
  },
  drawerInfo: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(4),
    alignItems: 'center',
    color: theme.palette.drawer.version.color,
    fontSize: '13px',
  },
  selectClub: {
    background: 'none',
    color: theme.palette.drawer.link.titleColor,
  },
  version: {
    textAlign: 'right',
  },
  bottom: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(2),
    gridAutoRows: 'min-content',
    padding: theme.spacing(3, 2),
  },
  logout: {
    cursor: 'pointer',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr min-content',
    minHeight: theme.spacing(4),
    alignItems: 'center',
    color: theme.palette.drawer.logout.color,
  },

  topbarContainer: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    display: 'grid',
    gridTemplateColumns: `${theme.spacing(2)}px auto`,
    gridTemplateRows: `${theme.spacing(3)}px minmax(0px, min-content)`,
    gridTemplateAreas: `"back title profile" 
      ". title ."`,
    gridColumnGap: theme.spacing(2),
    alignItems: 'center',
    fontSize: '18px',
    padding: theme.spacing(2, 3, 2, 3),
  },
  withSubtitle: {
    gridTemplateRows: `${theme.spacing(3)}px minmax(0px, min-content) ${theme.spacing(
      2,
    )}px minmax(0px, auto)`,
    gridTemplateAreas: `"back title profile" 
      ". title ."
      ". . ." 
      ". subtitle ."`,
    alignItems: 'center',
  },
  topBarBack: {
    gridArea: 'back',
    cursor: 'pointer',
    display: 'grid',
    alignSelf: 'center',
  },
  topBarTitle: {
    gridArea: 'title',
    cursor: 'pointer',
    alignSelf: 'start',
    color: theme.palette.topbar.title,
    fontWeight: '600',
    fontSize: '18px',
  },
  topBarTitlePrimary: {
    cursor: 'default',
    color: theme.palette.topbar.title,
    fontWeight: '600',
    fontSize: '18px',
    alignSelf: 'center',
  },
  topBarSubtitle: {
    gridArea: 'subtitle',
    color: theme.palette.topbar.subtitle,
    fontWeight: '500',
    fontSize: '15px',
    alignSelf: 'center',
  },
  topBarProfile: {
    gridArea: 'profile',
    cursor: 'pointer',
    display: 'grid',
    alignSelf: 'center',
  },
  topBarDark: {
    gridArea: 'dark',
    cursor: 'pointer',
    display: 'grid',
    alignSelf: 'center',
  },
}));

const alertSeverity = ['info', 'warning', 'error', 'success'];
export default function TopbarPageLayout(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const queryClient = useQueryClient();
  const { i18n } = useLingui();
  const { clubId } = useParams();
  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n._locale);
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentClubId, setCurrentClubId] = useState(clubId);
  const [openAlert, setOpenAlert] = useState(true);

  const closeDrawerAndNavigate = route => {
    setShowDrawer(false);
    history.push(route);
  };

  const handleToExitConfirm = async () => {
    logout();
    setShowDrawer(false);
    history.replace(routes.login);
  };

  const onClubChange = async newId => {
    if (newId === 'add-new-club') {
      // Open page add-new-club-page
      history.push(routes.clubNew);
    } else {
      setCurrentClubId(newId);
      await queryClient.invalidateQueries({
        predicate: ({ queryKey }) => queryKey[0] !== 'getClubsOwned',
      });
    }
  };

  const onLanguageChange = newLanguage => {
    setCurrentLanguage(newLanguage);
    setShowDrawer(false);
  };

  useEffect(() => {
    i18n.activate(currentLanguage);
  }, [currentLanguage, i18n]);

  const { isFetching, data } = useQuery([`getClubsOwned`], () => clubApi.getClubsOwned());
  const handleReadMore = () => {
    setOpenAlert(false);
    history.push(fillRoute(routes.alert, { clubId }));
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const currentClub = data.find(({ id }) => id == currentClubId);
    if (!currentClub) {
      return;
    }
    const isPaid =
      currentClub.freeForever ||
      currentClub.brand?.enabled ||
      currentClub.subscriptionExpiration > Date.now();
    if (!isPaid) {
      history.push(
        fillRoute(routes.clubPayment, {
          clubId: currentClubId,
        }),
      );
    } else {
      if (currentClubId !== clubId) {
        history.push(
          fillRoute(routes.menuList, {
            clubId: currentClubId,
          }),
        );
      }
    }
  }, [currentClubId, data, history, clubId]);

  if (isFetching) {
    return <Spinner />;
  }

  // TODO: remove this
  // eslint-disable-next-line
  const currentClub = data.find(({ id }) => id == currentClubId);

  // TODO: history.replace doesn't work, if you go back from browser after going back it probably doesn't work
  return (
    <>
      <SwipeableDrawer
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        onOpen={() => setShowDrawer(true)}
      >
        <div className={classes.drawerContainer}>
          <div className={classes.drawerLanguageContainer}>
            <div className={classes.drawerLanguageItem}></div>
            {languages.map(language => {
              return (
                <div
                  key={language}
                  onClick={() => onLanguageChange(language)}
                  className={`${classes.drawerLanguageItem} ${
                    currentLanguage === language ? classes.drawerLanguageItemSelected : ''
                  }`}
                >
                  <img
                    src={`/assets/flags/${language}.svg`}
                    alt={language}
                    width="25"
                    style={{ margin: 'auto' }}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.drawerItemContainer}>
            <div className={classes.drawerInfo}>
              <div className={classes.version}>v{__APP_VERSION__}</div>
              <div className={classes.version}>{import.meta.env.MODE}</div>
            </div>

            <FormControl variant="outlined" className={classes.selectClub}>
              {isAdmin() ? (
                <Autocomplete
                  sx={{ width: 1 }}
                  options={data
                    .map(({ name, id }) => ({ label: `${name} (${id})`, value: id }))
                    .concat([{ label: i18n._('Add a new club'), value: 'add-new-club' }])}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  value={data
                    .map(({ name, id }) => ({ label: `${name} (${id})`, value: id }))
                    .find(({ value }) => value == currentClubId)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      onClubChange(newValue.value);
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      hiddenLabel
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'search',
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: 'text.disabled', mr: 1 }} />
                          </InputAdornment>
                        ),
                        sx: { pb: 1 },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                />
              ) : (
                <Select native value={clubId} onChange={({ target }) => onClubChange(target.value)}>
                  {data.map(({ name, id }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                  <option value="add-new-club">{i18n._('Add a new club')}</option>
                </Select>
              )}
            </FormControl>

            <Divider />

            <>
              <div className={classes.drawerContentTitle}>{i18n._('MENU')}</div>
              <div
                className={clsx(classes.drawerItem, classes.drawerItemEnabled)}
                onClick={() =>
                  closeDrawerAndNavigate(
                    fillRoute(routes.menuList, {
                      clubId,
                    }),
                  )
                }
              >
                <span>{i18n._('Write your menus')}</span>
                <NavigateNextIcon />
              </div>
              <Divider />

              <div className={classes.drawerContentTitle}>{i18n._('YOUR CLUB')}</div>
              <div
                className={clsx(classes.drawerItem, classes.drawerItemEnabled)}
                onClick={() =>
                  closeDrawerAndNavigate(fillRoute(routes.clubProfileList, { clubId }))
                }
              >
                <span>{i18n._('Your club')}</span>
                <NavigateNextIcon />
              </div>
              <div
                className={clsx(classes.drawerItem, classes.drawerItemEnabled)}
                onClick={() => closeDrawerAndNavigate(fillRoute(routes.clubQrCode, { clubId }))}
              >
                <span>{i18n._('Share your QR Code')}</span>
                <NavigateNextIcon />
              </div>
            </>
          </div>

          <div className={classes.bottom}>
            <div className={classes.logout} onClick={() => setShowLogoutConfirmationModal(true)}>
              <span>{i18n._('Log out')}</span>
              <NavigateNextIcon />
            </div>
          </div>
        </div>
      </SwipeableDrawer>
      <MultiselectionModal
        show={showLogoutConfirmationModal}
        title={i18n._('Confirm Exit')}
        onConfirm={handleToExitConfirm}
        onCancel={() => setShowLogoutConfirmationModal(false)}
      />
      <div className={classes.container} translate="no">
        <div>
          {currentClub.message && (
            <Collapse in={openAlert}>
              <Alert
                severity={alertSeverity[currentClub.message.severity]}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {currentClub.message.summary}
                {currentClub.message.fullMessage && (
                  <strong onClick={handleReadMore}>{i18n._('Read more')}</strong>
                )}
              </Alert>
            </Collapse>
          )}
          <div className={classes.topbarContainer}>
            <div className={classes.topBarBack}>
              {props.onBack ? (
                <ArrowBackIosNewIcon
                  fontSize="small"
                  onClick={() => history.replace(props.onBack)}
                />
              ) : (
                <MenuIcon size="18" onClick={() => setShowDrawer(!showDrawer)} />
              )}
            </div>
            <div className={[classes.topBarTitle, classes.topBarTitlePrimary].join(' ')}>
              {props.title}
            </div>
          </div>
        </div>
        <div className={classes.content}>{props.isLoading ? <Spinner /> : props.children}</div>
      </div>
    </>
  );
}
