import React, { useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { menuApi, clubApi, isAdmin } from '@services/api';
import { useLingui } from '@lingui/react';
import {
  Box,
  Button,
  Container,
  Divider,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Spinner } from '@common/Spinner';
import { useHistory, useParams } from 'react-router-dom';
import { MultiselectionModal } from 'components/modals/multiselection-modal';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';

const additionalNotesList = [
  'Gluten Free',
  'Senza lattosio',
  'Vegetariano',
  'Vegano',
  'Potrebbe contenere tracce di Nichel',
  'Prodotti BIO',
  'Piccante',
  'Congelato',
  'Surgelato',
  'Prodotto Abbattuto',
  'Fatto in casa',
];

const allergensList = [
  'Pesce',
  'Molluschi',
  'Latticini',
  'Glutine',
  'Frutta a guscio',
  'Crostacei',
  'Arachidi',
  'Lupini',
  'Uova',
  'Anidride solforosa e solfiti',
  'Soia',
  'Sesamo',
  'Senape',
  'Sedano',
];

export function EditItemPage(props) {
  const queryClient = useQueryClient();
  const params = useParams();
  const history = useHistory();

  const { clubId, menuId, sectionId, itemId } = params;
  const [name, setName] = useState('');
  const [initialName, setInitialName] = useState('');
  const [description, setDescription] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [position, setPosition] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [allergens, setAllergens] = useState('');
  const [priceText, setPriceText] = useState('');
  const [outOfStock, setOutOfStock] = useState(false);
  const [suggested, setSuggested] = useState(false);
  const [bestSeller, setBestSeller] = useState(false);
  const [translateName, setTranslateName] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showAllergensModal, setShowAllergensModal] = useState(false);
  const [branded, setBranded] = useState(false);

  const { i18n } = useLingui();
  const { isInitialLoading } = useQuery(
    [`getMenuItem_${clubId}_${itemId}_${menuId}_${sectionId}`],
    async () => {
      const response = await menuApi.getMenuItem(clubId, itemId, menuId, sectionId);
      setName(response.name ?? '');
      setInitialName(response.name ?? '');
      setDescription(response.description ?? '');
      setIngredients(response.ingredients ?? '');
      setPosition(response.position ?? 0);
      setDisabled(response.disabled ?? false);
      setAdditionalNotes(response.additionalNotes ?? '');
      setAllergens(response.allergens ?? '');
      setPriceText(response.priceText ?? response.price?.toString() ?? '');
      setOutOfStock(response.outOfStock ?? false);
      setSuggested(response.suggested ?? false);
      setBestSeller(response.bestSeller ?? false);
      setTranslateName(response.translateName ?? false);
      console.log(response.branded);
      setBranded(response.branded ?? false);

      return response;
    },
    {
      enabled: itemId != null,
    },
  );

  const { isLoading: isLoadingClub, data: clubData } = useQuery([`getClub_${clubId}`], () =>
    clubApi.getClub(clubId),
  );

  const mutation = useMutation(
    event => {
      event.preventDefault();
      const formattedPrice = priceText?.replace(',', '.');
      let priceValue;
      let priceTextValue;
      if (isNaN(formattedPrice)) {
        priceValue = 0;
        priceTextValue = priceText;
      } else {
        priceValue = parseFloat(formattedPrice);
        priceTextValue = null;
      }

      return menuApi.createOrUpdateMenuItem(clubId, menuId, sectionId, {
        id: itemId,
        name,
        description,
        ingredients,
        price: priceValue,
        position,
        disabled,
        allergens,
        priceText: priceTextValue,
        outOfStock,
        suggested,
        bestSeller,
        translateName,
        additionalNotes,
        branded,
      });
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        if (itemId != null) {
          queryClient.invalidateQueries([`getMenuItem_${clubId}_${itemId}_${menuId}_${sectionId}`]);
        }
        history.push(fillRoute(routes.menuItemList, { clubId, menuId, sectionId }));
      },
    },
  );

  const handleChangeDisabled = () => {
    if (!disabled) {
      setOutOfStock(false);
    }
    setDisabled(!disabled);
  };

  const handleChangeOutOfStock = () => {
    if (!outOfStock) {
      setDisabled(false);
      setBestSeller(false);
      setSuggested(false);
    }
    setOutOfStock(!outOfStock);
  };

  const handleChangeBestSeller = () => {
    if (!bestSeller) {
      setSuggested(false);
      setOutOfStock(false);
    }
    setBestSeller(!bestSeller);
  };

  const handleChangeSuggested = () => {
    if (!suggested) {
      setBestSeller(false);
      setOutOfStock(false);
    }
    setSuggested(!suggested);
  };

  if (isInitialLoading || isLoadingClub || mutation.isLoading) {
    return <Spinner />;
  }

  return (
    <TopbarPageLayout
      title={initialName || i18n._('New item')}
      onBack={fillRoute(routes.menuItemList, { clubId, menuId, sectionId })}
    >
      <Container
        sx={{
          py: { xs: 6, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
        }}
      >
        <form onSubmit={mutation.mutate}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            <TextField
              label={i18n._('Name')}
              name="name"
              value={name}
              onChange={({ target }) => setName(target.value)}
              margin="normal"
              variant="outlined"
              required
              multiline
              inputProps={{ maxLength: 100, pattern: '.*\\S+.*' }}
            />

            <TextField
              label={i18n._('Price')}
              name="price"
              value={priceText}
              onChange={({ target }) => setPriceText(target.value)}
              margin="normal"
              variant="outlined"
              required
              multiline
              inputProps={{ maxLength: 254, pattern: '.*\\S+.*' }}
            />

            <TextField
              label={i18n._('Description')}
              name="description"
              value={description}
              onChange={({ target }) => setDescription(target.value)}
              margin="normal"
              variant="outlined"
              multiline
              inputProps={{ maxLength: 254, pattern: '.*\\S+.*' }}
            />

            <TextField
              label={i18n._('Ingredients')}
              name="ingredients"
              value={ingredients}
              onChange={({ target }) => setIngredients(target.value)}
              margin="normal"
              variant="outlined"
              multiline
              inputProps={{ maxLength: 254, pattern: '.*\\S+.*' }}
            />

            <TextField
              label={i18n._('Supplementary Notes')}
              name="notes"
              value={additionalNotes}
              margin="normal"
              variant="outlined"
              multiline
              readOnly
              InputProps={{
                maxLength: 254,
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDownIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onClick={() => setShowNotesModal(true)}
            />

            <TextField
              label={i18n._('Allergens')}
              name="allergens"
              value={allergens}
              margin="normal"
              variant="outlined"
              onClick={() => setShowAllergensModal(true)}
              readOnly
              multiline
              InputProps={{
                maxLength: 254,
                pattern: '.*\\S+.*',
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDownIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />

            <Stack
              direction={{ xs: 'row' }}
              justifyContent={{ xs: 'space-between' }}
              alignItems={{ xs: 'center' }}
            >
              <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                {i18n._('Set as Out of Stock')}
              </Typography>
              <Switch
                checked={outOfStock}
                onChange={handleChangeOutOfStock}
                color="primary"
                name="outOfStock"
                inputProps={{ 'aria-label': 'checkbox' }}
              />
            </Stack>

            <Stack
              direction={{ xs: 'row' }}
              justifyContent={{ xs: 'space-between' }}
              alignItems={{ xs: 'center' }}
            >
              <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                {i18n._('Hide Item')}
              </Typography>
              <Switch
                checked={disabled}
                onChange={handleChangeDisabled}
                color="primary"
                name="disabled"
                inputProps={{ 'aria-label': 'checkbox' }}
              />
            </Stack>

            {clubData.menuLabelEnabled && (
              <>
                <Divider />
                <Stack
                  direction={{ xs: 'row' }}
                  justifyContent={{ xs: 'space-between' }}
                  alignItems={{ xs: 'center' }}
                >
                  <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                    {i18n._('Bestseller')}
                  </Typography>
                  <Switch
                    checked={bestSeller}
                    onChange={handleChangeBestSeller}
                    color="primary"
                    name="bestSeller"
                    inputProps={{ 'aria-label': 'checkbox' }}
                  />
                </Stack>
                <Stack
                  direction={{ xs: 'row' }}
                  justifyContent={{ xs: 'space-between' }}
                  alignItems={{ xs: 'center' }}
                >
                  <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                    {i18n._('Recommended')}
                  </Typography>
                  <Switch
                    checked={suggested}
                    onChange={handleChangeSuggested}
                    color="primary"
                    name="suggested"
                    inputProps={{ 'aria-label': 'checkbox' }}
                  />
                </Stack>
              </>
            )}
            {isAdmin() && (
              <Stack
                direction={{ xs: 'row' }}
                justifyContent={{ xs: 'space-between' }}
                alignItems={{ xs: 'center' }}
              >
                <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                  {i18n._('Branded product')}
                </Typography>
                <Switch
                  checked={branded}
                  onChange={() => setBranded(!branded)}
                  color="primary"
                  name="branded"
                  inputProps={{ 'aria-label': 'checkbox' }}
                />
              </Stack>
            )}

            <Divider />

            <Stack
              direction={{ xs: 'row' }}
              justifyContent={{ xs: 'space-between' }}
              alignItems={{ xs: 'center' }}
            >
              <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                {i18n._('Translate name')}
              </Typography>
              <Switch
                checked={translateName}
                onChange={e => setTranslateName(!translateName)}
                color="primary"
                name="translateName"
                inputProps={{ 'aria-label': 'checkbox' }}
              />
            </Stack>
          </Stack>

          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              p: 3,
              pt: 2,
              display: 'flex',
              justifyContent: 'space-around',
              bgcolor: '#f5f5f5',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                borderRadius: '25px',
                px: 8,
                py: 1.5,
                bgcolor: '#397FF7',
              }}
            >
              {i18n._('Save Item')}
            </Button>
          </Box>
        </form>
        <MultiselectionModal
          show={showAllergensModal}
          title={i18n._('Add Allergens')}
          message={i18n._('Select the allergens to add...')}
          items={allergensList}
          selectedItems={allergens?.split(', ').filter(value => allergensList.includes(value))}
          multiselection={true}
          onConfirm={selectedItems => {
            setAllergens(selectedItems.join(', '));
            setShowAllergensModal(false);
          }}
          onCancel={() => setShowAllergensModal(false)}
        />
        <MultiselectionModal
          show={showNotesModal}
          title={i18n._('Add Notes')}
          message={i18n._('Select the notes to add...')}
          items={additionalNotesList}
          selectedItems={additionalNotes
            ?.split(', ')
            .filter(value => additionalNotesList.includes(value))}
          multiselection={true}
          onConfirm={selectedItems => {
            setAdditionalNotes(selectedItems.join(', '));
            setShowNotesModal(false);
          }}
          onCancel={() => setShowNotesModal(false)}
        />
      </Container>
    </TopbarPageLayout>
  );
}
